import React from 'react';
import PropTypes from 'prop-types';

import { nl2br } from '../../utils/utils';
import Stream from '../../shared/Stream';
import { AH } from '../shared/AccessibleHeading';

class VideoSection extends React.Component {
  hasContent() {
    return !!(this.props.customVideoHeading || this.props.customVideoContent);
  }

  renderVideoColumn() {
    return (
      <div className={`col-md-${this.hasContent() ? '6' : '12'} col-xs-12`}>
        <div className="pm-video-section-video">
          {this.props.customVideoCloudflareId && (
            <div className="pm-cloudflare-video">
              <Stream
                autoplay={false}
                controls
                cloudflareId={this.props.customVideoCloudflareId}
                height="auto"
                muted={false}
                poster={this.props.customVideoPreviewThumbnail}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  renderContentColumn() {
    if (!this.hasContent()) {
      return null;
    }
    return (
      <div className="col-md-6 col-xs-12">
        {this.props.customVideoHeading && (
          <AH variant="h3">{this.props.customVideoHeading}</AH>
        )}
        {this.props.customVideoContent && (
          <p>{nl2br(this.props.customVideoContent)}</p>
        )}
      </div>
    );
  }

  render() {
    if (!this.props.customVideoUrl) {
      return null;
    }
    return (
      <div className="pm-video-section fr-view">
        <div className="row">
          {this.renderVideoColumn()}
          {this.renderContentColumn()}
        </div>
      </div>
    );
  }
}

VideoSection.defaultProps = {
  customVideoCloudflareId: null,
  customVideoContent: null,
  customVideoHeading: null,
  customVideoPreviewThumbnail: null,
  customVideoTranscriptionUrl: null,
  customVideoUrl: null,
};

VideoSection.propTypes = {
  customVideoCloudflareId: PropTypes.string,
  customVideoContent: PropTypes.string,
  customVideoHeading: PropTypes.string,
  customVideoPreviewThumbnail: PropTypes.string,
  customVideoTranscriptionUrl: PropTypes.string,
  customVideoUrl: PropTypes.string,
};

export default VideoSection;
